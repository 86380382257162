/* FactorGraph.css */

/* Estilo para el contenedor principal */

  
.factor-graph-title {
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
  text-align: center;
}

/* Contenedor del gráfico */
.chart-container {
  width: 100%;
  /* max-width: 90%; */
  /* height: 700px; */
  margin-bottom: 32px;
}
.chart-container {
  background-color: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* padding: 20px; */
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  padding: 16px;
  transition: box-shadow 0.3s ease;
  border: 1px solid rgba(229, 231, 235, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
}

.chart-container:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Contenedor específico para el canvas del gráfico */
.chart-wrapper {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.factor-graph-title {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #111827;
  margin-bottom: 16px;
  font-size: 1.25rem;
  text-align: left;
  padding: 0 16px;
}

@media (max-width: 768px) {
  /* .chart-container {
    height: 200px;
  } */
}

@media (max-width: 576px) {
  .factor-graph-title {
    font-size: 1rem;
  }

  /* .chart-container {
    height: 280px;
  } */
}
