/* Governance.css */
.governance-container {
  padding: 2rem;
  background: #ffffff;
  min-height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
}

.governance-header {
  text-align: center;
  margin-bottom: 3rem;
}

.governance-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.governance-header p {
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.governance-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 3rem;
}

.data-input-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.input-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(37, 99, 235, 0.1);
}

.input-card h2 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  display: block;
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid rgba(37, 99, 235, 0.2);
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.input-group input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.charts-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.kpi-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.kpi-card {
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(37, 99, 235, 0.1);
  transition: all 0.3s ease;
}

.kpi-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.kpi-card h3 {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.kpi-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2563eb;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.chart-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(37, 99, 235, 0.1);
  height: calc(50% - 1rem);
}

.chart-card h2 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.chart-container {
  height: calc(100% - 3rem);
  position: relative;
}

.request-feature-card {
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(37, 99, 235, 0.1);
  text-align: center;
  max-width: 800px;
  margin: 4rem auto 0;
}

.request-feature-card h3 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.request-feature-card p {
  color: #666;
  margin-bottom: 2rem;
}

.feature-input {
  width: 100%;
  max-width: 400px;
  padding: 1rem;
  border: 1px solid rgba(37, 99, 235, 0.2);
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.feature-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.feature-button {
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  color: white;
  border: none;
  padding: 1rem 3rem;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.feature-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

.feature-button:active {
  transform: translateY(0);
}

.save-button-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 100;
}

.save-button {
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.save-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.save-button:active {
  transform: translateY(0);
}

.last-save {
  font-size: 0.8rem;
  color: #666;
  text-align: center;
  margin-top: 0.5rem;
}

@media (max-width: 1024px) {
  .governance-content {
    grid-template-columns: 1fr;
  }

  .chart-card {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .governance-container {
    padding: 1rem;
  }

  .governance-header h1 {
    font-size: 2rem;
  }

  .governance-header p {
    font-size: 1rem;
  }

  .chart-card {
    height: 300px;
  }

  .request-feature-card {
    padding: 1.5rem;
  }
}