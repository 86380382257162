/* Allgemeine Stile */
.gesamt-emissionen-container {
  padding: 2rem;
  background-color: #ffffff;
}

.page-title {
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
  color: #0c001b;
}

/* Stile für die Übersichtskarten */
.summary-cards {
  margin-bottom: 2rem;
}

.summary-card {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.summary-card:hover {
  transform: translateY(-5px);
}

.equal-height {
  display: flex;
  flex-direction: column;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-icon {
  width: 60px;
  height: 60px;
  background-color: #2196f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  color: #fff;
}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.card-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2196f3;
}

.card-subtitle {
  font-size: 0.9rem;
  color: #888;
  margin-top: auto;
  padding-bottom: 1rem;
}

/* Stile für die Hauptfaktoren */
.factors-list {
  width: 100%;
  margin-top: 1rem;
}

.factor-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.factor-name {
  font-weight: 600;
  color: #333;
}

.factor-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2196f3;
}

/* Stile für den Hauptdiagrammcontainer */
.chart-container {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  max-width: 100%!important;

}



/* Stile für die Steuerungen */
.controls-container {
  margin-bottom: 2rem;
}

/* Teilen-Button */
.share-button-container {
  margin-bottom: 2rem;
}

.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.custom-btn {
  background-color: #2196f3;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.custom-btn:hover {
  transform: translateY(-2px);
  background-color: #1976d2;
}

/* Stile für den Teilen-Dialog */
.share-dialog {
  text-align: center;
}

.share-link {
  margin: 20px 0;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  word-break: break-all;
}

.qr-code {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* Responsivität */
@media (max-width: 768px) {
  .summary-cards {
    flex-direction: column;
  }

  .card-value {
    font-size: 2rem;
  }



  .factor-item {
    margin-bottom: 1rem;
  }
}