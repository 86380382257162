/* App.css */

/* Variables globales */
:root {
  /* Tipografía */
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  /* Botones */
  --button-background-color: #ffffff;
  --button-text-color: #146994;
  --button-border-radius: 12px;
  --button-padding: 10px 20px;
  --button-font-size: 16px;
  --button-hover-bg-color: #f5f5f5;
  --button-active-transform: translateY(2px);
  --button-active-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  --button-focus-shadow: 0 0 0 3px rgba(0, 122, 255, 0.4);
  --button-disabled-bg-color: #e0e0e0;
  --button-disabled-text-color: #e2e2e2;

  /* Sombras */
  --button-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --button-hover-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  --button-active-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

  /* Encabezados */
  --heading-color: #333333;
  --heading-font-weight: 600;
  --heading-line-height: 1.2;

  /* Layout */
  --header-height: 60px;
  --sidebar-width: 240px;

  /* Transiciones */
  --transition-speed: 0.3s;

  /* Otros */
  --background-color: #f5f5f5;
}

/* Reset de márgenes y paddings */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-size: 1rem;
  line-height: 1.6;
  font-family: var(--font-family);
  background-color: var(--background-color);
}

/* Contenedor principal */
.App {
  width: 100%;
}

/* Área de contenido */
.content-area {
  
  margin-top: var(--header-height);
}

/* Cuando el Sidebar está visible, agrega margen izquierdo */
.content-area.with-sidebar {
  margin-left: var(--sidebar-width);
}

/* Pantalla de actualización */
.update-screen {
  background-color: #f8f9fa;
  color: #343a40;
  font-family: 'Arial', sans-serif;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}

/* Clases de utilidad */
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

/* Estilos para encabezados */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.75rem;
  font-family: var(--font-family);
  color: var(--heading-color);
  font-weight: var(--heading-font-weight);
  line-height: var(--heading-line-height);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

/* Estilos para párrafos */
p {
  font-size: 1rem;
  margin: 0.75rem 0;
  color: #555555;
}

/* Botones - Estilo global */
button.custom-btn, .custom-btn {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  padding: var(--button-padding);
  border-radius: var(--button-border-radius);
  font-size: var(--button-font-size);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family);
  cursor: pointer;
  transition: background-color var(--transition-speed), box-shadow var(--transition-speed), transform 0.2s ease;
  box-shadow: var(--button-shadow);
  display: inline-flex;
  align-items: center;
  gap: 8px;
  text-shadow: none;
}

/* Estilo para los SVG dentro de los botones */
button.custom-btn svg, .custom-btn svg {
  font-size: 16px;
}

/* Hover - Efecto al pasar el cursor */
button.custom-btn:hover, .custom-btn:hover {
  background-color: var(--button-hover-bg-color);
  box-shadow: var(--button-hover-shadow);
}

/* Active - Efecto al hacer clic */
button.custom-btn:active, .custom-btn:active {
  transform: var(--button-active-transform);
  box-shadow: var(--button-active-shadow);
}

/* Focus - Estilo de enfoque para accesibilidad */
button.custom-btn:focus, .custom-btn:focus {
  outline: none;
  box-shadow: var(--button-focus-shadow);
}

/* Disabled - Estilo para botones deshabilitados */
button.custom-btn:disabled, .custom-btn:disabled {
  background-color: var(--button-disabled-bg-color);
  color: var(--button-disabled-text-color);
  cursor: not-allowed;
  box-shadow: none;
}

/* Enlaces */
a {
  color: #007aff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Imágenes responsivas */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Ajustes responsivos */
@media (max-width: 767.98px) {
  /* Para móviles, ajustamos el margen */
  .content-area.with-sidebar {
    margin-left: 0;
    margin-bottom: 60%; /* Altura del Sidebar en móviles */
  }
}
