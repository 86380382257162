@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Variables de color y tipografía */
:root {
  --sidebar-bg-color: #ffffff;
  --sidebar-border-color: rgba(229, 231, 235, 0);
  --nav-link-color: rgb(55, 65, 81);
  --nav-link-hover-bg: rgba(243, 244, 246, 0);
  --nav-link-active-bg: linear-gradient(to right, rgb(48, 185, 180), rgb(37, 99, 235));
  --nav-link-active-color: #ffffff;
  --section-title-color: rgb(107, 114, 128);
  --icon-color: rgb(107, 114, 128);
  --transition-speed: 0.3s;
  --header-height: 70px;
  --sidebar-width: 260px;
  --font-family: 'Inter', sans-serif;
}

/* Reset y estilos base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
}

/* Contenedor principal de la sidebar */
.sidebar {
  background-color: var(--sidebar-bg-color);
  height: 100vh;
  width: var(--sidebar-width);
  position: fixed;
  top: var(--header-height);
  left: 0;
  overflow-y: auto;
  transition: transform var(--transition-speed) ease-in-out;
  z-index: 1;
}

/* Navegación */
.sidebar-nav {
  padding: 1rem 0;
}

.nav-list {
  list-style: none;
  padding: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  color: var(--nav-link-color);
  cursor: pointer;
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

.nav-item .icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: var(--icon-color);
  transition: color var(--transition-speed);
}

.nav-item:hover {
  background-color: var(--nav-link-hover-bg);
}

.nav-item:hover .icon {
  color: rgb(59, 130, 246);
}

.nav-item.active {
  background: var(--nav-link-active-bg);
  color: var(--nav-link-active-color);
  border-radius: 0 1.5rem 1.5rem 0;
}

.nav-item.active .icon {
  color: var(--nav-link-active-color);
}

.link-text {
  font-size: 1rem;
  font-weight: 500;
}

/* Secciones */
.section-title {
  font-size: 0.75rem;
  color: var(--section-title-color);
  padding: 1rem 1.5rem 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;
}

/* Responsividad */
@media (max-width: 991px) {
  .sidebar {
    width: 220px;
  }

  .nav-item .icon {
    font-size: 1.4rem;
  }

  .link-text {
    font-size: 0.95rem;
  }
}

@media (max-width: 767px) {
  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  /* Aumentar el índice Z para que el sidebar esté por encima del contenido */
  .sidebar {
    z-index: 999;
  }
}