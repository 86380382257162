@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Reset de estilos básicos */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* Contenedor principal */
.analyse-container {
  max-width: 100%;
  padding: 2rem;
  background-color: #ffffff;
}

/* Encabezado */
.analyse-header {
  text-align: center;
  margin-bottom: 2rem;
}

.analyse-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #0c001b;
  margin-bottom: 0.5rem;
}

.analyse-subtitle {
  font-size: 1.25rem;
  color: #555;
}

/* Sección de análisis */
.analyse-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Tarjeta de análisis */
.analyse-card {
  background: #ffffff;
  border-radius: 1rem;
  border: 1px solid rgba(229, 231, 235, 0.6);
  padding: 2rem;
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-icon {
  width: 4rem;
  height: 4rem;
  background: linear-gradient(135deg, #2a7c85, #0f205e);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  color: #fff;
  font-size: 2rem;
  box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease;
}

.analyse-card:hover .card-icon {
  transform: scale(1.1);
}

.card-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #0c001b;
  margin-bottom: 1rem;
}

.card-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

/* Formulario de carga */
.upload-form {
  width: 100%;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #999999;
  transition: border 0.3s ease;
  margin-bottom: 20px;
}

.dropzone.active {
  border-color: #357EDD;
  color: #357EDD;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.custom-button {
  background: linear-gradient(to right, #357EDD, #2a6fbd);
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.3s ease, background 0.3s ease;
}

.custom-button:hover {
  transform: translateY(-2px);
  background: linear-gradient(to right, #2a6fbd, #1f5ea5);
}

.custom-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Sección de archivos pendientes */
.pending-files-section {
  margin-bottom: 2rem;
}

.file-item {
  background-color: rgba(245, 245, 245, 0.5);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
}

.custom-btn-delete {
  background: none;
  border: none;
  color: #ff4d4d;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.custom-btn-delete:hover {
  color: #ff1a1a;
}

/* Sección de resultados */
.results-section {
  margin-top: 2rem;
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;
}

.date-filter {
  flex: 1;
  min-width: 200px;
}

.factor-filter {
  flex: 2;
  min-width: 250px;
}

.export-button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-width: 200px;
}

.analysis-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
}

.analysis-table thead th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #0c001b;
  text-align: center;
  padding: 10px;
}

.analysis-table th, .analysis-table td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
}

.analysis-table tbody tr:hover {
  background-color: #f1f1f1;
}

.bemerkungen-column {
  max-width: 150px;
}

.custom-btn-action {
  background: none;
  border: none;
  color: #357EDD;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.custom-btn-action:hover {
  color: #2a6fbd;
}

/* Modales */
.modal-header {
  background-color: #f8f9fa;
}

.modal-title {
  color: #0c001b;
}

.modal-body {
  color: #555;
}

/* Responsividad */
@media (max-width: 768px) {
  .filters-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .export-button {
    justify-content: center;
    width: 100%;
  }

  .analysis-table th, .analysis-table td {
    font-size: 0.9rem;
  }
}