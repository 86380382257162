/* Berichte.css */

.berichte-container {
  position: relative;
  padding: 20px;

  /* Weitere vorhandene Stile */
}

.berichte-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.berichte-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.berichte-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3; /* Hellgrau */
  border-top: 8px solid #3498db; /* Blau */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.message {
  margin-top: 20px;
  text-align: center;
  font-size: 1.2em;
  color: #333;
}
