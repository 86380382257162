@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.esg-container {
  min-height: 100vh;
  padding: 3rem 1rem;
  background: linear-gradient(135deg, rgb(238, 242, 255) 0%, rgb(255, 255, 255) 50%, rgb(250, 245, 255) 100%);
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
}

.esg-header {
  text-align: center;
  margin-bottom: 4rem;
}

.esg-badge {
  display: inline-block;
  padding: 0.375rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  background: linear-gradient(to right, rgba(168, 85, 247, 0.1), rgba(59, 130, 246, 0.1));
  color: rgb(126, 34, 206);
  letter-spacing: -0.025em;
}

.esg-title {
  font-size: clamp(2.5rem, 5vw, 3rem);
  font-weight: 700;
  line-height: 1.1;
  background: linear-gradient(to right, rgb(17, 24, 39), rgb(75, 85, 99));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 1.5rem 0 1rem;
  letter-spacing: -0.025em;
}

.esg-subtitle {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  color: rgb(75, 85, 99);
  max-width: 36rem;
  margin: 0 auto;
  line-height: 1.5;
  letter-spacing: -0.025em;
}

.esg-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.esg-card {
  position: relative;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12px);
  border-radius: 1.5rem;
  border: 1px solid rgba(229, 231, 235, 0.6);
  padding: 2.5rem;
  transition: all 0.5s ease;
  overflow: hidden;
}

.esg-card:hover {
  transform: translateY(-6px);
  box-shadow: 
    0 25px 30px -10px rgba(0, 0, 0, 0.1),
    0 12px 12px -8px rgba(0, 0, 0, 0.05);
}

.esg-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.75rem;
  transition: transform 0.5s ease;
}

.esg-card:hover .esg-icon {
  transform: scale(1.15);
}

.esg-icon.emerald {
  background: linear-gradient(135deg, rgb(16, 185, 129), rgb(5, 150, 105));
  box-shadow: 0 6px 8px -2px rgba(16, 185, 129, 0.3);
}

.esg-icon.blue {
  background: linear-gradient(135deg, rgb(59, 130, 246), rgb(37, 99, 235));
  box-shadow: 0 6px 8px -2px rgba(59, 130, 246, 0.3);
}

.esg-icon.purple {
  background: linear-gradient(135deg, rgb(139, 92, 246), rgb(124, 58, 237));
  box-shadow: 0 6px 8px -2px rgba(139, 92, 246, 0.3);
}

.esg-card-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: rgb(17, 24, 39);
  margin-bottom: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.esg-card-description {
  font-size: 1rem;
  color: rgb(107, 114, 128);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.esg-button {
  width: 100%;
  padding: 0.85rem 1.75rem;
  border-radius: 0.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  letter-spacing: -0.02em;
}

.esg-button.emerald {
  background: linear-gradient(to right, rgb(16, 185, 129), rgb(5, 150, 105));
}

.esg-button.blue {
  background: linear-gradient(to right, rgb(59, 130, 246), rgb(37, 99, 235));
}

.esg-button.purple {
  background: linear-gradient(to right, rgb(139, 92, 246), rgb(124, 58, 237));
}

.esg-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.1);
}

.bx-right-arrow-alt {
  transition: transform 0.3s ease;
}

.esg-button:hover .bx-right-arrow-alt {
  transform: translateX(6px);
}

.esg-footer {
  text-align: center;
  margin-top: 4rem;
  color: rgb(107, 114, 128);
  font-size: 0.875rem;
  letter-spacing: -0.025em;
}

@media (max-width: 640px) {
  .esg-container {
    padding: 2rem 1rem;
  }
  
  .esg-header {
    margin-bottom: 3rem;
  }
  
  .esg-card {
    padding: 2rem;
  }
  
  .esg-icon {
    width: 3.5rem;
    height: 3.5rem;
  }
  
  .esg-card-title {
    font-size: 1.5rem;
  }
  
  .esg-card-description {
    font-size: 0.9375rem;
  }
  
  .esg-button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9375rem;
  }
}