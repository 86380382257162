/* Scope.css */
.scope-container {
  padding: 2rem;
  background: #ffffff;
  min-height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
}

.scope-header {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;
}

.scope-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  background-clip: text;
  background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scope-header p {
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}
.emissions-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(37, 99, 235, 0.1);
  margin-bottom: 2.5rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
}

.emissions-value {
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 1rem 0;
  line-height: 1;
}

.emissions-label {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.emissions-period {
  font-size: 1rem;
  color: #888;
  margin-top: 0.5rem;
  font-weight: 400;
}

.factors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  position: relative;
}

.factor-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
}

.factor-card:hover {
  box-shadow: 0 8px 25px rgba(37, 99, 235, 0.15);
  border-color: #2563eb;
  transform: translateY(-2px);
}

.factor-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.factor-card h3 {
  font-size: 1.25rem;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.factor-card p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0;
  line-height: 1.5;
}

.factor-emissions {
  font-size: 1.2rem;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  margin-top: 0.5rem;
}

.card-arrow {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: #2563eb;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.factor-card:hover .card-arrow {
  opacity: 1;
  transform: translateX(0);
}

/* Request Factor Card Styles */
.request-factor-card {
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  grid-column: 1 / -1; /* Span all columns */
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(37, 99, 235, 0.1);
  margin-top: 4rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}

.request-factor-card h3 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
  text-align: center;
}

.request-factor-card p {
  color: #666;
  margin-bottom: 2rem;
  max-width: 600px;
  text-align: center;
}

.request-factor-input {
  width: 100%;
  max-width: 400px;
  padding: 1rem;
  border: 1px solid rgba(37, 99, 235, 0.2);
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-align: center;
}

.request-factor-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.request-factor-button {
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  color: white;
  border: none;
  padding: 1rem 3rem;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto;
  min-width: 200px;
}

.request-factor-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

.request-factor-button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .factors-grid {
    grid-template-columns: 1fr;
  }

  .factor-card {
    height: auto;
    padding: 1.5rem;
  }

  .scope-container {
    padding: 1rem;
  }

  .scope-header h1 {
    font-size: 2rem;
  }

  .scope-header p {
    font-size: 1rem;
  }

  .chart-section {
    padding: 1rem;
  }

  .chart-container {
    height: 300px;
  }

  .factors-grid {
    grid-template-columns: 1fr;
  }
}