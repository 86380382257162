/* Wesentlichkeitsanalyse.css */

/* Asegura que todos los elementos usan box-sizing: border-box */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Contenedor principal */
.wesentlichkeitsanalyse-container {
  display: flex;
  flex-direction: column;
  align-items: center;

}

/* Botón en la esquina superior derecha */
.top-right {
  align-self: center;
  margin-bottom: 16px;
}

/* Contenedor del gráfico */
.chart-container {
  width: 100%;
  max-width: 90%;
  height: 700px;
  margin-bottom: 32px;
}

.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

/* Tarjeta de explicación */
.explanation-card {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 32px;
}

/* Ajustes para títulos y párrafos */
.explanation-card h3 {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
}

.explanation-card h4 {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
}

.explanation-card p {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
}

/* Responsividad */
@media (max-width: 767.98px) {
 

  .explanation-card h3 {
    font-size: 22px;
  }

  .explanation-card h4 {
    font-size: 18px;
  }

  .explanation-card p {
    font-size: 16px;
  }
}
