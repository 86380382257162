/* Environmental.css */

/* Contenedor principal */
.environmental-container {
    padding: 20px;
  }
  
  /* Centrar el grupo de botones */
  .button-group-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  /* Diseño de cuadrícula para los gráficos */
  .factor-graphs-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos columnas */
    gap: 20px;
  }
  
  /* Asegurar que los gráficos llenen su contenedor */
  .factor-graph {
    width: 100%;
  }
  
  /* Responsividad para pantallas pequeñas */
  @media (max-width: 767.98px) {
    .factor-graphs-grid {
      grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
    }
  }
  