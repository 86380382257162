/* Impressum.css */
.impressum-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
    padding: 4rem 2rem;
  }
  
  .impressum-content {
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    padding: 3rem;
    backdrop-filter: blur(10px);
  }
  
  .impressum-header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .impressum-header h1 {
    font-size: 3rem;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
    background: linear-gradient(135deg, #2563eb, #3b82f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .subtitle {
    color: #666;
    font-size: 1.1rem;
  }
  
  .info-section {
    margin-bottom: 3rem;
  }
  
  .info-section h2 {
    font-size: 1.8rem;
    color: #1a1a1a;
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 0.5rem;
  }
  
  .info-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #2563eb, #3b82f6);
    border-radius: 2px;
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .info-card {
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .info-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .info-card h3 {
    color: #2563eb;
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }
  
  .info-card p {
    color: #4b5563;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .info-card a {
    color: #2563eb;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .info-card a:hover {
    color: #1d4ed8;
  }
  
  .support-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .support-card {
    background: linear-gradient(145deg, #ffffff, #f8f9ff);
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(37, 99, 235, 0.1);
  }
  
  .support-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .support-card h3 {
    color: #2563eb;
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    border-bottom: 2px solid rgba(37, 99, 235, 0.2);
    padding-bottom: 0.5rem;
  }
  
  .support-card p {
    color: #4b5563;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .support-note {
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(37, 99, 235, 0.1);
    font-style: italic;
    color: #6b7280;
  }
  
  .management-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
  }
  
  .management-card {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
  }
  
  .management-card:hover {
    transform: translateY(-3px);
  }
  
  .management-card span {
    color: #1a1a1a;
    font-weight: 500;
  }
  
  .register-info {
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .register-info p {
    color: #4b5563;
    margin-bottom: 0.5rem;
  }
  
  .legal-sections {
    display: grid;
    gap: 2rem;
  }
  
  .legal-section {
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .legal-section h2 {
    color: #1a1a1a;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .legal-section p {
    color: #4b5563;
    margin-bottom: 1rem;
    line-height: 1.7;
  }
  
  @media (max-width: 768px) {
    .impressum-container {
      padding: 2rem 1rem;
    }
  
    .impressum-content {
      padding: 2rem;
    }
  
    .impressum-header h1 {
      font-size: 2.5rem;
    }
  
    .info-section h2 {
      font-size: 1.5rem;
    }
  
    .management-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .impressum-header h1 {
      font-size: 2rem;
    }
  
    .info-card {
      padding: 1.5rem;
    }
  
    .legal-section {
      padding: 1.5rem;
    }
  }