/* DataContent.css */

/* Farbvariablen für einfache Anpassungen */
:root {
    --farbe-hintergrund: #ffffff;
    --farbe-hintergrund-abschnitt: #ffffff;
    --farbe-text: #333333;
    --farbe-text-sekundaer: #666666;
    --farbe-akzent: #007aff; /* Blau ähnlich wie Apple */
    --farbe-grenze: #e0e0e0;
    --farbe-hover: #f0f0f0;
}

/* Allgemeine Schriftfamilie */
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
                 Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Hauptcontainer */
.data-content-container {
    display: flex;
    flex-direction: column;

    background-color: var(--farbe-hintergrund-abschnitt);
    min-height: 100vh;
    padding: 20px;
}

/* Abschnitt für jeden Scope */
.scope-section {
    margin-bottom: 40px;
}

/* Kopfzeile für den Scope */
.scope-header-card {
    display: flex;
    align-items: center;
    background-color: var(--farbe-hintergrund);
    padding: 15px 20px;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.scope-header-card h2 {
    margin: 0;
    margin-left: 10px;
    font-size: 1.5rem;
    color: var(--farbe-text);
}

/* Icons für den Scope */
.scope-icon {
    font-size: 1.5rem;
    color: var(--farbe-akzent);
}

/* Container für die Karten */
.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Einzelne Karte */
.card {
    background-color: var(--farbe-hintergrund);
    border: 1px solid var(--farbe-grenze);
    border-radius: 12px;
    width: calc(33.333% - 20px);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    padding: 20px;
}

.card:hover {
    background-color: var(--farbe-hover);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Inhalt der Karte */
.card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.card-body span {
    font-size: 1rem;
    color: var(--farbe-text);
}

/* Chevron-Icon */
.chevron-icon {
    color: var(--farbe-akzent);
    width: 16px;
    height: 16px;
}

/* Responsives Design */
@media (max-width: 1024px) {
    .card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 600px) {
    .cards-container {
        flex-direction: column;
    }

    .card {
        width: 100%;
    }

    .scope-header-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .scope-header-card h2 {
        margin-left: 0;
        margin-top: 10px;
        font-size: 1.25rem;
    }
}
