/* Header.css */

/* 1. Benutzerdefinierte Stile für das Header */
.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduzierter Schatten */
  height: var(--header-height); /* Höhe aus CSS-Variablen */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 150; /* Höherer z-index für Überlagerung */
}

/* 2. Stile für das Logo */
.logo {
  height: 40px; /* Reduzierte Größe für kompakteres Design */
}
