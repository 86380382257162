/* Ubersicht.css */
.ubersicht-container {
  padding: 2rem;
  background: #ffffff;
  min-height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
}

.ubersicht-header {
  text-align: center;
  margin-bottom: 3rem;
}

.ubersicht-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
    background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ubersicht-header p {
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
}

.gesamt-emissionen {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  min-height: 450px;
  width: 100%;
  grid-column: 1 / -1;
}

.emissions-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  height: 100%;
}

.total-value {
  font-size: 4rem;
  font-weight: 700;
  color: #2563eb;
  text-align: center;
  line-height: 1;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
    background-clip: text;
  -webkit-text-fill-color: transparent;
}

.total-value .unit {
  display: block;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  opacity: 0.8;
}

.emissions-breakdown {
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  width: 100%;
  max-width: 800px;
}

.breakdown-item {
  text-align: center;
  padding: 1.5rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  flex: 1;
  transition: transform 0.3s ease;
}

.breakdown-item:hover {
  transform: translateY(-5px);
}

.breakdown-item .label {
  display: block;
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.breakdown-item .value {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2563eb;
}

.metric-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(37, 99, 235, 0.1);
  transition: all 0.3s ease;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.metric-card h2 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
}

.chart-container {
  flex: 1;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-stats,
.governance-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
  padding: 1rem;
}

.stat {
  text-align: center;
  padding: 1.5rem;
  background: rgba(37, 99, 235, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.stat:hover {
  background: rgba(37, 99, 235, 0.1);
}

.stat .label {
  display: block;
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.stat .value {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: #2563eb;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  color: #666;
  font-size: 1.1rem;
  text-align: center;
  background: rgba(37, 99, 235, 0.05);
  border-radius: 10px;
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .ubersicht-container {
    padding: 1rem;
  }

  .ubersicht-header h1 {
    font-size: 2rem;
  }

  .metrics-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .emissions-breakdown {
    flex-direction: column;
    gap: 1rem;
  }

  .metric-card {
    min-height: 500px;
    padding: 1.5rem;
  }

  .social-stats,
  .governance-stats {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    padding: 0.75rem;
  }
}