/* ConversionFactors.css */
.conversion-factors-container {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    min-height: 100vh;
    background: #ffffff;
  }
  
  .conversion-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .conversion-header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    background: linear-gradient(135deg, #2563eb, #3b82f6);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
  }
  
  .conversion-header p {
    font-size: 1.1rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .conversion-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  
  .scope-section {
    background: #ffffff;
    border-radius: 12px;
    overflow: hidden;
  }
  
  .scope-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .scope-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #2563eb, #3b82f6);
    border-radius: 8px;
    color: white;
    font-size: 1.2rem;
  }
  
  .scope-header h2 {
    font-size: 1.3rem;
    color: #1a1a1a;
    margin: 0;
    font-weight: 600;
  }
  
  .factors-list-container {
    display: flex;
    flex-direction: column;
  }
  
  .factor-list-item {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .factor-main-info {
    flex: 1;
  }
  
  .factor-title {
    margin-bottom: 0.75rem;
  }
  
  .factor-title h3 {
    font-size: 1.1rem;
    color: #1a1a1a;
    margin-bottom: 0.25rem;
  }
  
  .factor-source {
    font-size: 0.9rem;
    color: #666;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .source-icon {
    font-size: 0.8rem;
    color: #2563eb;
  }
  
  .factor-values {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .factor-value {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .value-number {
    font-size: 1.2rem;
    font-weight: 600;
    color: #2563eb;
  }
  
  .value-unit {
    font-size: 0.9rem;
    color: #666;
  }
  
  .agricultural-values {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .ag-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background: rgba(37, 99, 235, 0.05);
    border-radius: 6px;
  }
  
  .ag-label {
    color: #4b5563;
  }
  
  .ag-number {
    color: #2563eb;
    font-weight: 500;
  }
  
  .factor-actions {
    color: #9ca3af;
    transition: color 0.3s ease;
  }
  
  .factor-list-item:hover .factor-actions {
    color: #2563eb;
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    z-index: 1000;
    backdrop-filter: blur(8px);
  }
  
  .modal-content {
    background: white;
    border-radius: 20px;
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 
      0 0 0 1px rgba(255, 255, 255, 0.1),
      0 25px 50px -12px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(229, 231, 235, 0.5);
    animation: modalAppear 0.3s ease-out;
  }
  
  .modal-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
    position: sticky;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(8px);
  }
  
  .modal-header h2 {
    font-size: 1.5rem;
    color: #1a1a1a;
    margin: 0;
    font-weight: 600;
    background: linear-gradient(135deg, #2563eb, #3b82f6);
    -webkit-background-clip: text;
    background-clip: text;
    
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.025em;
  }
  
  .close-modal {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #2563eb;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 1;
    transition: all 0.3s ease;
    background: rgba(37, 99, 235, 0.1);
  }
  
  .close-modal:hover {
    transform: rotate(90deg);
    color: #1d4ed8;
    background: rgba(37, 99, 235, 0.2);
  }
  
  .modal-body {
    padding: 1.5rem;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .modal-section {
    background: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 
      0 1px 3px rgba(0, 0, 0, 0.05),
      0 1px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(37, 99, 235, 0.1);
    transition: all 0.3s ease;
  }
  
  .modal-section:hover {
    box-shadow: 
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-color: rgba(37, 99, 235, 0.2);
  }
  
  .modal-section h3 {
    font-size: 1.1rem;
    color: #1a1a1a;
    margin-bottom: 0.75rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    letter-spacing: -0.025em;
  }
  
  .modal-section p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin: 0;
    background: rgba(37, 99, 235, 0.03);
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid rgba(37, 99, 235, 0.05);
  }
  
  .modal-section a {
    color: #2563eb;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .modal-section a:hover {
    text-decoration: underline;
  }
  
  .modal-factor-value {
    font-size: 1.1rem;
  }
  
  .modal-ag-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background: rgba(37, 99, 235, 0.05);
    border-radius: 8px;
    margin-bottom: 0.5rem;
  }
  
  .single-value {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }
  
  .single-value strong {
    font-size: 1.3rem;
    color: #2563eb;
  }
  
  @keyframes modalAppear {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .conversion-factors-container {
      padding: 1rem;
    }
    
    .conversion-header h1 {
      font-size: 2rem;
    }
    
    .scope-header {
      padding: 1rem;
    }
    
    .factor-list-item {
      padding: 1rem;
    }
    
    .modal-content {
      margin: 1rem;
      max-height: calc(100vh - 2rem);
    }
    
    .modal-header {
      padding: 1rem;
    }
    
    .modal-body {
      padding: 1rem;
      gap: 1rem;
    }
    
    .modal-section {
      padding: 1rem;
    }
  }